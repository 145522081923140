
@import '../../style/rwd.scss';

.dui-MapViewContainer{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  background: var(--dui-bg-accent);
  display: flex;
  overflow: hidden;
    @include mobile{
        flex-direction: column;
    }
   .header-img-mb{
      display: none;
      @include mobile{
          display: block;
          background-image: var(--header-mb-img);
          background-size: cover;
          background-position: center;
          width: auto;
          height: 95px;
          margin: 5px;
          margin-bottom: 0px;
          border-radius: 10px 10px 0px 0px;
      }
   }
   
  .pd-space{
      margin: 15px;
      display: flex;
      flex: 1;
      border: 5px solid var(--dui-bg-secondary--brighten-20);
      border-radius: 20px;
      overflow: hidden;
      background: var(--dui-bg-primary);
      padding: 30px 40px 30px 0px;
      position: relative;
      @include mobile{
          margin: 5px;
          margin-top: 0px;
          padding: 0px;
          border-radius: 0px;
          border: none;
      }
      .credit {
          position: absolute;
          bottom: 0px;
          left: 0px;
          color: #cbcbcb;
          font-size: xx-small;
          white-space: pre-line;
          margin: 3px 15px;
          line-height: 1.3;
          a{
              color: currentColor;
              text-decoration: none;
          }
      }
      .map{
          z-index: 0;
          flex: 1;
          background: cadetblue;
          height: 100%;
          position: relative;
          border-radius: 20px;
          box-shadow: 10px 10px 0px 0px #8a8a8a;
          overflow: hidden;
          @include mobile {
              border-radius: 0px;
          }
          .dialog-container{
              pointer-events: none;
              position: absolute;
              z-index: 500;
              height: calc( 100% - 100px );
              min-width: 320px;
              max-width: 320px;
              top: 100px;
              left: 10px;
              @include mobile {
                  position: fixed;
                  z-index: 600;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  max-width: 90vw;
                  max-height: 90vh;
              }
          }
      }
  }
  
}