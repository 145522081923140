@import '../../style/rwd.scss';

.dui-ResponsiveDialog{
  background: var(--dui-bg-primary--opacity-95);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  max-height: 80vh;
  z-index: 700;
  min-width: 300px;
  min-height: 400px;
  box-shadow: 2px 2px 10px 1px #0000004a;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  pointer-events: auto;
  overflow: hidden;
  @include mobile{
    $marginSpace: 5px;
    max-width: unset;
    width: calc(100% - $marginSpace*2);
    transform: unset;
    bottom: 0px;
    left: $marginSpace;
    height: calc(100% - 165px);
    top: auto;
    max-height: unset;
    animation: showUp 0.3s;
    @keyframes showUp {
      0%{
        bottom: -1000px;
      }
      100%{
        bottom: 0px
      }
    }
  }

  &-disableFixed{
    position: relative;
    top: auto;
    left: auto;
    transform: unset;
    height: 100%;
    width: 100%;
  }

  &-kanban{
    img{
      width: 100%;
      max-height: 150px;
      object-fit: cover;
    }
    @include mobile {
      display: none;
    }
  }
  .header{
    margin-top: 10px;
    margin-left: 0px;
    min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title{
          color: black;
          opacity: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 15px;
          .icon{
              display: flex;
              margin: 0px 10px;
              & > *{
                  height: 32px;
                  width: auto;
              }
          }
          .text{
              font-size: 20px;
              color: #474645;
          }
      }
      .action{
          display: flex;
          justify-content: flex-end;
          .close-btn{
              cursor: pointer;
              font-size: large;
              margin-right: 20px;
              @include mobile{
                border: 1px solid;
                border-radius: 50%;
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              &:hover{
                  font-weight: bolder;
              }
          }
      }
  }
}