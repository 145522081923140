::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background: #b9c1c7;
}

::-webkit-scrollbar-track {
  background: fff0
}

body *{
  scrollbar-color: #b9c1c7 #fff0;
  scrollbar-width: thin !important;

  .marker-cluster-small {
    color: white;
    $bg: #ffc824d5;
    background-color: $bg;
    div { background-color: $bg; }
  }
  .marker-cluster-medium {
    color: white;
    $bg: #52b335d3;
    background-color: $bg;
    div { background-color: $bg; }
  }
  .marker-cluster-large {
    color: white;
    $bg: #cd7a67d5;
    background-color: $bg;
    div { background-color: $bg; }
  }
}

.dui-MapViewContainer{
  .leaflet-touch .leaflet-bar, .leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers {
    border-radius: 3px;
  }
}

.dui-MapViewContainer .leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background: var(--dui-bg-primary);
}


.dui-MapViewContainer .leaflet-container a.leaflet-popup-close-button{
  text-shadow: 0 0 1px black;
}

.dui-MapViewContainer {
  /* 右上工具位置 */
  .leaflet-right .leaflet-control {
    margin-right: 0px;
  }
  .leaflet-top .leaflet-control {
    margin-top: 8px;
  }
  /* 右下角leaflet位置 */
  .leaflet-bottom {
    bottom: 8px;
  }
  .leaflet-right {
    right: 8px;
  }
}

#root .Popup-Custom {
  background: #FCFAC8;
}