$height: 25px;
$offset: 6px;
$ball-size: $height - $offset;
$duration: .3s;

.Switch-Root {
  position: relative;
  display: inline-block;
  width: $ball-size * 2 + $offset;
  height: $height;
  > input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .Switch-Root-Slider {
      background-color: var(--dui-bg-secondary);;
    }

    &:focus + .Switch-Root-Slider  {
      box-shadow: 0 0 1px var(--dui-bg-secondary);;
    }

    &:checked + .Switch-Root-Slider::before {
      -webkit-transform: translateX($ball-size);
      -ms-transform: translateX($ball-size);
      transform: translateX($ball-size);
    }
  }

  &-Slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: $duration;
    transition: $duration;
    border-radius: 34px;

    &::before {
      position: absolute;
      content: "";
      height: $ball-size;
      width: $ball-size;
      left: $offset / 2;
      bottom: $offset / 2;
      background-color: white;
      -webkit-transition: $duration;
      transition: $duration;
      border-radius: 50%;
    }
  }
}