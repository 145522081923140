
.dui-button{
  background-color: var(--dui-bg-secondary);
  color: white;
  padding: 10px 20px;
  border: 0;
  border-radius: 30px;
  cursor: pointer;
  font-size: 18px;


  &:disabled {
    background-color: var(--dui-text-gray--opacity-80);
    cursor: auto;
  }
}