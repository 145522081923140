@import '../../../style/rwd.scss';

.dui-AboutWalkDialogContent{
  overflow: auto;
  margin: 0px 5px;
  padding: 0px 25px;
  flex: unset;
  display: unset;

  &-img{
    display: flex;
    justify-content: center;
    img{
      max-height: 400px;
      max-height: 400px;
      min-height: 200px;
      object-fit: contain;
      max-width: 400px;
      width: 100%;
      height: auto;
    }
  }

  &-title{
    font-size: 20px;
    font-weight: 700;
    margin: 15px 0px;
    text-align: center;
    display: grid;
    gap: 3px;
    color: var(--dui-bg-accent);
  }

  &-content{
    font-size: 18px !important;
    padding: 0 20px;
    text-align: justify;
    font-size: smaller;
    line-height: 25px;
  }
}