.SelectField-Root {
  &-SelectWrapper {
    $deco-space: 30px;
    position: relative;
    display: flex;
    align-items: center;
    &::after {
      content: '';
      margin-left: -$deco-space;
      background: url(./arrow-down.svg) no-repeat center;
      height: 20px;
      width: 20px;
      z-index: 1;
    }

    & > select {
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
      position: relative;
      padding-right: $deco-space;
      width: 100%;
    }
  }
}