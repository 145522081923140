@import '../../../style/rwd.scss';

.TreeCard-Root{
  min-width: 350px;
  min-height: 300px;
  max-width: 400px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s all;
  background: white;
  margin: 20px;
  margin-top: 0;
  padding: 20px;

  @include mobile{
    max-height: unset;
    flex: 1;
    min-width: initial;
  }
  &-Header {
    display: flex;
    border-bottom: 1px solid gray;
    padding-bottom: 20px;
    gap: 10px;
    &-Thumb {
      width: 65px;
      height: 65px;
      border-radius: 100%;
      object-fit: cover;
      outline: 3px solid gray;
      margin: 3px;
    }
    &-Title {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      &-Main {
        font-size: 22px;
      }
      &-Sub {
        border-radius: 35px;
        background: var(--dui-bg-accent);
        color: white;
        padding: 5px 10px;
        text-align: center;
        font-size: 12px;
        width: fit-content;
      }
    }
  }

  &-Info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 25px 0;

    &-Item {
      display: flex;
      align-items: center;
      > span {
        white-space: nowrap;
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }

  &-More {
    width: 100%;
  }
}