.CheckBoxesField-Root {
  margin-left: 15px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  &-Item {
    display: flex;
    align-items: center;
    label {
      white-space: nowrap;
    }
  }
}