.dui-ToolSettingDialogContent{
  overflow: auto;
  flex: 1;
  margin-right: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  

  & > * {
    margin: 0 35px;
  }

  &-ToolSet {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-Tool {
      display: flex;
      justify-content: space-between;
    }
  }
}