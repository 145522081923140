@import '../../../style/rwd.scss';

.dui-TreeSearchDialogContent{
  overflow: auto;
  flex: 1;
  margin-right: 5px;
  padding-right: 5px;
  padding-bottom: 5px;

  & > * {
    margin: 0 35px;
  }  
  
  &-form {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &-result {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-number{
      font-size: 100px;
      color: var(--dui-primary);
    }
    &-title{
    }
  }
}